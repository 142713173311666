<template>
  <section style="height: 100%">
    <div v-if="hasSubmit">
      <v-btn @click="getReport">Get Report</v-btn>
    </div>
    <div
      style="height: 100%"
      class="mt-4"
      id="reportContainer"
      ref="reportContainer"
    ></div>
  </section>
</template>

<style>
#reportContainer {
  width: 100%;
}

#reportContainer iframe {
  border: none;
}
</style>

<script>
import * as powerbi from "powerbi-client";

export default {
  name: "PowerBIReport",
  props: {
    reportId: { type: String, required: true },
    sliceFilter: {},
    hasSubmit: { type: Boolean, default: false },
    maxHeight: { type: Boolean, default: true },
    height: { type: Number, default: -1 },
  },
  watch: {
    reportId: function (newVal) {
      if (!this.hasSubmit && newVal && newVal !== "") {
        this.getReport();
      }
    },
  },
  created() {
    this.getReport();
  },

  methods: {
    getReport: function () {
      // const self = this;
      const url = `/v1/viewsupport/report/embedconfiguration/${this.reportId}`;

      let loadedResolve,
        reportLoaded = new Promise((res) => {
          loadedResolve = res;
        });

      // let renderedResolve,
      //   reportRendered = new Promise((res) => {
      //     renderedResolve = res;
      //   });

      // Embed a Power BI report in the given HTML element with the given configurations
      // Read more about how to embed a Power BI report in your application here: https://go.microsoft.com/fwlink/?linkid=2153590
      function embedPowerBIReport(container, configuration) {
        /*-----------------------------------------------------------------------------------+
        |    Don't change these values here: access token, embed URL and report ID.          | 
        |    To make changes to these values:                                                | 
        |    1. Save any other code changes to a text editor, as these will be lost.         |
        |    2. Select 'Start over' from the ribbon.                                         |
        |    3. Select a report or use an embed token.                                       |
        +-----------------------------------------------------------------------------------*/
        // Embed the report and display it within the div container.
        const pbi = new powerbi.service.Service(
          powerbi.factories.hpmFactory,
          powerbi.factories.wpmpFactory,
          powerbi.factories.routerFactory
        );

        let report = pbi.embed(container, configuration);

        // report.off removes all event handlers for a specific event
        report.off("loaded");

        // report.on will add an event handler
        report.on("loaded", function () {
          loadedResolve();
          report.off("loaded");
        });

        // report.off removes all event handlers for a specific event
        report.off("error");

        report.on("error", function (event) {
          console.log(event.detail);
        });

        // report.on will add an event handler
        // report.on("rendered", function () {
        //   renderedResolve();
        //   report.off("rendered");
        // });

        // // report.off removes all event handlers for a specific event
        // report.off("rendered");

        //report.off removes all event handlers for a specific event
        // clears the event handlers before starting the event listener
        report.off("dataSelected");

        // report.on start the event listener
        report.on("dataSelected", async function (event) {
          // store the events
          let data = event.detail;
          console.log("Event - dataSelected:\n", data);

          // set the variables for the slicers that need to be adjusted
          let slicerThatChanged = "Site Slicer";
          let slicersToReset = ["Monitor Name Slicer"];

          // filter events for only slicers
          if (data.visual.type != "slicer") return;

          try {
            // run function to reset slicers
            await ResetSlicers(
              data.visual.name,
              slicerThatChanged,
              slicersToReset
            );
          } catch (errors) {
            console.log(errors);
          }
        });

        async function ResetSlicers(
          changedSlicerName,
          slicerToListenToName,
          slicersToResetNames
        ) {
          // get list of all slicers on active page
          let pageSlicers = await getSlicersForActivePage();

          // get the slicer visual and compare with the visual that fired the data selected event
          let changingSlicer = pageSlicers.filter(
            (s) => s.title === slicerToListenToName
          )[0];
          if (changingSlicer.name != changedSlicerName) return;

          // for each slicer to be reset reset them.
          let slicersToReset = pageSlicers.filter(
            (s) => slicersToResetNames.indexOf(s.title) != -1
          );

          slicersToReset.map(async (s) => {
            await s.setSlicerState({ filters: [] });
          });
        }

        // Select Run and select an element of a visualization.
        // For example, a bar in a bar chart. You should see an entry in the Log window.
        console.log("Select data to see events in Log window.");
        async function getSlicersForActivePage() {
          // Retrieve the page collection and get the visuals for the active page.
          try {
            const pages = await report.getPages();
            // Retrieve the active page.
            let pageWithSlicer = pages.filter(function (page) {
              return page.isActive;
            })[0];
            const visuals = await pageWithSlicer.getVisuals();
            // Retrieve the target visual.
            let slicers = visuals.filter(function (visual) {
              if (visual.type === "slicer") console.log("Visual: ", visual);
              return visual.type === "slicer";
            });
            // Get the slicer state
            return slicers;
          } catch (errors) {
            console.log(errors);
            return [];
          }
        }

        return report;
      }

      this.axios.get(url).then(async (response) => {
        const container = this.$refs.reportContainer;
        console.log(response.data.data);
        let { token, report, reportParameters } = response.data.data;
        console.log(report);
        console.log(reportParameters);
        const embedConfiguration = {
          type: "report",
          tokenType: 1,
          accessToken: token.token,
          embedUrl: report.embedUrl,
          id: report.id,
          settings: {
            filterPaneEnabled: false,
          },
        };

        let pbireport = embedPowerBIReport(container, embedConfiguration);

        await reportLoaded;

        // if (self.sliceFilter) {
        //   console.log("slice filter");
        //   console.log(self.sliceFilter);
        //   siteName = self.sliceFilter.siteName;
        //   interval = self.sliceFilter.interval;
        //   range = self.sliceFilter.range;
        // }

        const siteSlicerFilter = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "vwSite",
            column: "SiteName",
          },
          operator: "In",
          values: [reportParameters.SiteName],
        };

        const dateSlicerFilter = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "vwReportingDateTime",
            column: "Date",
          },
          logicalOperator: "And",
          conditions: [
            {
              operator: "GreaterThanOrEqual",
              value: reportParameters.StartDate,
            },
            {
              operator: "LessThanOrEqual",
              value: reportParameters.EndDateTime,
            },
          ],
        };

        const monitorNameSlicerFilter = {
          $schema: "http://powerbi.com/product/schema#advanced",
          target: {
            table: "vwSensorNames",
            column: "MonitorFriendlyName",
          },
          operator: "In",
          values: [reportParameters.MonitorFriendlyName],
        };

        // Retrieve the page collection and get the visuals for the active page.
        try {
          const pages = await pbireport.getPages();

          // Retrieve the active page.
          let page = pages.filter(function (page) {
            return page.isActive;
          })[0];

          const visuals = await page.getVisuals();
          // Retrieve the target visuals.

          let siteSlicerVisual = visuals.filter(function (visual) {
            return visual.type === "slicer" && visual.title === "Site Slicer";
          })[0];

          let dateSlicerVisual = visuals.filter(function (visual) {
            return visual.type === "slicer" && visual.title === "Date Slicer";
          })[0];

          let monitorNameSlicerVisual = visuals.filter(function (visual) {
            return (
              visual.type === "slicer" && visual.title === "Monitor Name Slicer"
            );
          })[0];

          //await reportRendered;
          if (siteSlicerVisual) {
            // Set the slicer state which contains the slicer filters.
            await siteSlicerVisual.setSlicerState({
              filters: [siteSlicerFilter],
            });
            console.log("Site slicer was set.");
          }

          if (dateSlicerVisual) {
            await dateSlicerVisual.setSlicerState({
              filters: [dateSlicerFilter],
            });
            console.log("date slicer was set.");
          }

          if (monitorNameSlicerVisual) {
            await monitorNameSlicerVisual.setSlicerState({
              filters: [monitorNameSlicerFilter],
            });
            console.log("monitor name slicer was set.");
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
};
</script>