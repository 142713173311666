<template>
  <section style="height: 100%">
    <PowerBIReport
      :sliceFilter="reportFilters"
      :reportId="reportId"
      :hasSubmit="false"
      ref="powerBIReport"
    ></PowerBIReport>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import PowerBIReport from "./PowerBIReport";

export default {
  name: "ReportDetailsModule",
  data() {
    return {
      report: null,
      reportId: this.$route.params.id,
     };
  },
  props: { reportFilters: { type: Object, required: false } },

  components: {
    PowerBIReport,
  },
  computed: {
    ...mapGetters({
      userId: "session/jwtSubject",
      permissions: "session/userPermissions",
    }),
  },
  watch: {
    // reportFilters(newVal) {
    // }
  },
  created() {
  },
};
</script>